import * as React from "react";

import { Container, Header, Icon, Segment } from "semantic-ui-react";

import { Link } from "gatsby";
import { SEOPagesDescription } from "../utils/seo.util";
import Seo from "../components/SEO/Seo";
import { withLayout } from "../components/Layout";

const AboutPage = () => {
  return (
    <>
    <Seo {...SEOPagesDescription.about} />
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>
            About
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <p>
          This starter was created by @fabien0102.
        </p>
        <p>
          For any question, I'm on
          <Link
            to="https://discord.gg/2bz8EzW"
            target="blank"> discord #reactiflux/gatsby</Link>
        </p>
        <p>
          For any issues, any PR are welcoming
          <Link
            to="https://github.com/fabien0102/gatsby-starter/issues"
            target="blank"> on this repository</Link>
        </p>
      </Segment>
    </Container>
    </>
  );
};

export default withLayout(AboutPage);
